.react-datepicker {
  margin-top: -10px;
  border: solid 1px #006ac3;
  border-radius: 0px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-name:first-child {
  color: red;
}

.react-datepicker__day-name:last-child {
  color: #588dff;
}

.react-datepicker__week > :first-child {
  color: red;
}

.react-datepicker__week > :last-child {
  color: #588dff;
}

.react-datepicker__day--selected {
  background-color: #006ac3;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
  background-image: url(../../assets/calendar-gray.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 48px;
  pointer-events: none;
}

.react-datepicker__input-container:focus:after,
.react-datepicker__input-container:hover:after {
  background-image: url(../../assets/calendar.svg);
}

.react-datepicker__input-container > input {
  width: 229px;
  height: 42px;
  background-color: #f0f0f0;
  border: solid 1px transparent;
  transition: border 0.2s ease;
  font-size: 16px;
  padding-left: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.react-datepicker__input-container > input:hover {
  border: solid 1px #006ac3;
}

.react-datepicker__input-container > input:focus {
  outline: none;
  border: solid 1px #006ac3;
  border-bottom: none;
}

.react-datepicker__day--outside-month {
  color: #939191 !important;
}

.react-datepicker__header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
