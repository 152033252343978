.stampBoxMobile {
  //모바일
  display: flex;
  margin-top: 24px;
  margin-bottom: 18px;
  width: 100%;
  .stampInfo {
    margin-left: 8px;
    border: 1px solid #b8b7ba;
    border-radius: 6px;
    &:first-child {
      margin-left: 0;
    }
    .stampImg {
      width: 100%;
    }
    .stampTag {
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      .stampType {
        color: #2852af;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.29;
        letter-spacing: -0.28px;
      }
      .price {
        color: #333;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        .won {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.29;
        }
      }
    }
  }
}

.stampBox {
  //pc
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 24px 30px;
  width: 514px;
  border: 1px solid #8f8f8f;
  border-radius: 10px;
  background-color: #fff;
  .boxTitle {
    color: #333;
    font-size: 16px;
    font-weight: 400;
  }
  .stampInfo {
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
    .stamp {
      margin-left: 14px;
      &:first-child {
        margin-left: 0;
      }
      .stampImg {
        width: 100%;
      }
      .stampTag {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
        .stampType {
          color: #333;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.29;
        }
        .price {
          color: #2852af;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.2;
          .won {
            display: inline-block;
            margin-left: 2px;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.29;
          }
        }
      }
    }
  }
}
