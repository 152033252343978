* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #404040;
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  font-weight: 400;

  font-size: 14px;
  line-height: 1.4em;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
}
