@import '../../common/variable';
@import '../../common/mixin';

$width: 755px;

$content-width: 750px;

$h1-top-margin: 16px;
$h1-bottom-margin: 80px;
$h1-font-size: 30px;

$h2-bottom-margin: 40px;
$h2-line-height: 26px;
$h2-font-size: 24px;

$h2-content-between-margin: 100px;

$h3-font-size: 18px;
$h3-line-height: 16px;
$h3-bottom-margin: 24px;
$h3-padding: 16px;
$pricing-table-padding: 23px;

$content-headline-font-size: 16px;
$content-left-padding: 20px;

$content-font-size: 15px;
$content-line-height: 29px;
$content-font-color: #6b6b6b;

@mixin content($color: $content-font-color) {
  font-size: $content-font-size;
  line-height: $content-line-height;
  color: $color;
}

$content-sub-font-size: 14px;
$content-sub-line-height: 26px;
$content-sub-font-color: #909090;

@mixin subcontent($color: $content-sub-font-color) {
  font-size: $content-sub-font-size;
  line-height: $content-sub-line-height;
  color: $color;
}

$paragraph-bottom-margin: 16px;
$list-bottom-margin: 48px;
$list-left-padding: 15px;

@mixin nanum-square() {
  font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;
}

@mixin heading($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 400;
  color: $text-color;

  strong {
    font-weight: 600;
  }
}

@mixin list() {
  $list-item-between-margin: 10px;
  $list-item-nested-between-margin: 5px;

  padding-left: $list-left-padding;
  margin-top: 0;
  margin-bottom: $list-bottom-margin;

  li {
    @include content();

    p {
      margin: 0;
    }

    ol,
    ul {
      @include subcontent();
      margin-top: $list-item-between-margin;

      li + li {
        margin-top: $list-item-nested-between-margin;
      }
    }
  }

  li + li {
    margin-top: $list-item-between-margin;
  }
}

@mixin no-list-style() {
  list-style-type: none;
  text-indent: inherit;

  li:before {
    display: none;
  }
}

@mixin unordered-list-style() {
  list-style-type: none;
  text-indent: -15.26px;

  li > * {
    text-indent: 0;
  }

  li:before {
    // NOTE: content is 6.26 px
    content: '•';
    letter-spacing: 9px;
  }
}

@mixin yellow-block-content() {
  $block-margin: 48px;
  $block-padding: 20px;

  @include nanum-square();

  display: block;
  background-color: #fffdea;
  border: solid 1px #f5edae;
  border-radius: 4px;

  margin: 0 0 $block-margin;
  padding: $block-padding;

  p {
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 0;

    font-size: 14px;
    line-height: 28px;
    font-weight: 400;

    &:first-child {
      font-size: 16px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    strong {
      font-weight: 600;
    }
  }

  p + p {
    margin-bottom: 4px;
  }
}

@mixin table-style-list($left-width) {
  @include no-list-style();
  $table-list-border: 1px solid #e9e9e9;

  padding: 0;

  > li {
    &:first-child {
      border-top: $table-list-border;
    }
    &:last-child {
      border-bottom: $table-list-border;
    }
  }

  > li + li {
    border-top: $table-list-border;
  }

  li {
    padding: 10px 15px;
    background-color: #f5f5f5;
    font-size: 16px;
    color: $text-color;

    position: relative;

    ul {
      @include no-list-style();
      margin: 0;
      padding: 0;

      position: absolute;
      left: $left-width;
      right: 0;
      top: 0;
      bottom: 0;

      li {
        background-color: white;
        font-size: $content-font-size;
        color: #909090;

        position: relative;
        height: 100%;

        a {
          position: absolute;
          top: 12.5px;
          right: 10px;

          @include find-icon();
        }
      }
    }
  }

  > li + li {
    margin-top: 0;
    border-top: $table-list-border;
  }
}

@mixin find-icon($icon-size: 24px) {
  text-decoration: none;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  min-height: $icon-size;
  line-height: $icon-size;

  &:before {
    content: '';
    background-image: url('/assets/img/pages/pricing/find.svg');
    background-size: $icon-size;
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    position: relative;
    left: 0;
    margin-right: 8px;
  }
}

#view-desktop {
  .container {
    padding-top: 15px;
    padding-bottom: 100px;
  }

  .pricing-content {
    > div {
      margin-left: $width - $content-width;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul,
      a {
        font-weight: 400;
      }

      > h1 {
        margin-top: $h1-top-margin;
        margin-bottom: $h1-bottom-margin;

        @include heading($h1-font-size, 1em);
        @include nanum-square();

        letter-spacing: -0.7px;

        br {
          display: none;
        }
      }

      > h2 {
        margin-top: 0;
        margin-bottom: $h2-bottom-margin;

        @include heading($h2-font-size, $h2-line-height);
        @include nanum-square();

        // h2 style
        background-color: white;
        display: inline-block;
        width: auto;
        position: relative;
        padding-right: 25px;

        // bar after content
        &:after {
          content: '';
          display: block;
          border-bottom: 1px solid #e0e0e0;

          width: $content-width;
          position: absolute;
          z-index: -1;
          left: 0;
          top: $h2-line-height / 2;
        }
      }

      // p + h2
      > p {
        & + h2 {
          margin-top: $h2-content-between-margin - $paragraph-bottom-margin;
        }

        & + h3 {
          margin-top: $list-bottom-margin;
        }
      }

      // ol, ul + h2
      > ul,
      > ol {
        & + h2 {
          margin-top: $h2-content-between-margin - $list-bottom-margin;
        }
      }

      // table + h2
      table {
        & + h2 {
          margin-top: $h2-content-between-margin + $pricing-table-padding;
        }
      }

      // h3 + content
      > h3 {
        & + ol,
        & + ul {
          padding-left: $list-left-padding + $content-left-padding;
        }

        & + p {
          padding-left: $content-left-padding;
        }
      }

      // h2 밑에 바로 컨텐츠가 올 경우
      > h2 {
        & + ol,
        & + ul {
          & > li {
            @include content();
          }
        }
      }

      > h3,
      pricing-calculator > h3 {
        margin-top: 0;
        margin-bottom: $h3-padding;

        @include heading($h3-font-size, $h3-line-height);
        @include nanum-square();

        // h3 style
        // HACK: See https://css-tricks.com/injecting-line-break/
        display: table;
        padding: 0 16px;
        font-weight: 400;

        line-height: 24px;

        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 6px;
          background-color: #e9e9e9;
          border-radius: 2px;
        }
      }

      > p {
        @include content();

        margin-top: 0;
        margin-bottom: $paragraph-bottom-margin;
      }

      ol,
      ul {
        @include list();
      }

      ul {
        @include unordered-list-style();
      }

      p > img {
        max-width: 100%;

        // this is a desktop stylesheet
        &[alt*='_mobile'] {
          display: none;
        }
      }

      #calculation {
        & + p {
          padding-left: 0;
        }
      }

      a {
        font-size: $content-font-size;
        color: $helpme-main-color-darken;
        display: inline-block;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      // benefit-img
      #benefit {
        // first 3 row
        $margin: 15px;

        & + p {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin: -1 * $margin;

          img {
            margin: $margin;

            // this is a desktop stylesheet
            &[alt*='_mobile'] {
              display: none;
            }
          }

          img,
          img + img {
            width: calc(50% - #{$margin * 2});
            height: 188px;
          }

          img + img + img,
          img + img + img + img,
          img + img + img + img + img {
            width: calc(100% - #{$margin * 2});
            height: 133px;
          }
        }

        // image caption
        & + p + ul {
          text-align: center;
          list-style-type: none;
          padding-left: 0;
          margin-top: 20px;

          li {
            line-height: 1em;
            font-size: 14px;
          }

          li:before {
            content: '* ';
            color: $red-accent-color;
          }
        }
      }

      // pricing_description-blockquote
      #pricing_description {
        & + blockquote,
        & + * + blockquote {
          @include yellow-block-content();
        }
      }

      #pricing_list {
        & + ul {
          @include no-list-style();

          margin: 0;
          padding: 0;

          li {
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid #ececec;
            padding: 30px 100px;

            font-size: 18px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            // not treating as element
            &:before {
              display: none;
            }

            em {
              font-size: $content-headline-font-size;
              font-style: normal;

              code {
                color: black;
                font-size: 30px;
                font-family: 'Noto Sans KR', sans-serif;
              }
            }
          }

          li + li {
            margin-top: 10px;
          }
        }

        & + ul + hr {
          border: none;

          & + ul {
            @include list();
          }
        }
      }

      // pricing-table
      #pricing {
        // just for line break
        & + hr,
        & + p + hr {
          margin: 0;
          border: none !important;
        }

        &,
        & + hr,
        & + p + hr {
          // HACK: eliminate spacing between tables
          // left table
          & + table {
            position: relative;
            left: $pricing-table-padding + 2px;

            &:before {
              border-right: none !important;
            }

            // border between left and right table
            tr td {
              &:nth-child(2) {
                border-right: 1px solid #e9e9e9;
              }
            }
          }
          // right table
          & + table + table {
            position: relative;
            right: 1px;

            &:before {
              border-left: none !important;
            }
          }

          & + table,
          & + table + table {
            border-collapse: collapse;
            margin-top: $pricing-table-padding;
            vertical-align: top;

            display: inline-table;
            width: calc(50% - #{($pricing-table-padding / 2 + 1px)});

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: $pricing-table-padding * -1;
              left: $pricing-table-padding * -1;
              right: $pricing-table-padding * -1;
              bottom: $pricing-table-padding * -1;

              border-radius: 4px;
              border: solid 1px #e0e0e0;
            }

            thead,
            tbody {
              tr {
                th,
                td {
                  font-weight: 400;

                  strong,
                  em {
                    font-weight: 600;
                  }

                  em {
                    font-style: normal !important;
                    color: #eb4d59;
                  }

                  // text
                  &:nth-child(2n + 1) {
                    text-align: left;
                    padding-left: 40px;
                  }
                  // number
                  &:nth-child(2n) {
                    text-align: right;
                    padding-right: 40px;
                  }
                }
              }
            }

            thead {
              tr {
                border-bottom: 2px solid $text-color;
                position: relative;

                // HACK: line-height exceeds height 80px
                height: 90px;

                th {
                  padding: 40px - $pricing-table-padding 0 40px;
                  font-size: 20px;

                  &:first-child {
                    padding-left: 0;
                    transform: translateX(50%);
                  }

                  &:last-child {
                    padding-right: 0;
                    text-align: right;

                    position: absolute;
                    top: 0;
                    right: -270px;
                    width: 120%;
                    line-height: 1.2em;

                    strong {
                      font-size: 30px;
                      color: black;
                    }

                    br {
                      display: none;
                    }
                  }
                }
              }
            }

            tbody {
              tr + tr {
                border-top: 1px solid #e9e9e9;
              }

              tr {
                & {
                  // HACK: line-height exceeds height 50px
                  height: 60px;

                  th,
                  td {
                    font-weight: 400;

                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 14px;
                    color: #909090;
                  }
                }

                &:first-child {
                  border-bottom: 1px solid $text-color;
                  // HACK: line-height exceeds height 60px
                  height: 65px;

                  th,
                  td {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: $content-headline-font-size;
                    color: $text-color;
                  }
                }

                &:nth-child(2) {
                  border-top: none;
                }
              }
            }
          }

          & + table + table + ul {
            @include list();
            margin-top: 20px + $pricing-table-padding;
          }
        }
      }

      // tax-reduction-table
      #taxreduction {
        & + p {
          margin-bottom: $h2-bottom-margin;
        }

        & + ul,
        & + p + ul {
          @include table-style-list(250px);
        }
      }

      h3#taxreduction {
        display: none;
      }

      // strength-ul {
      #strength {
        $img-width: 70px;
        $img-margin: 15px;

        & + ul {
          padding-left: $img-width + $img-margin;

          &,
          & ul {
            @include no-list-style();
          }

          p {
            margin: 0;
          }

          li {
            position: relative;
            font-size: 16px;
            color: $text-color;

            img {
              position: absolute;
              left: -1 * ($img-width + $img-margin);
              max-width: $img-width;
            }

            ul {
              margin-top: 5px;
              margin-bottom: 0;
              padding-left: 0;

              li {
                font-size: $content-font-size;
                color: $content-font-color;
              }
            }
          }

          li + li {
            margin-top: 16px;
          }
        }
      }

      // deduction-p-ul
      #deduction {
        $padding: 0;

        & + p + ul {
          @include no-list-style();

          margin-top: 0;
          padding-left: $padding;
        }
      }

      // densearea_description-pre
      #densearea_description {
        $densearea-description-width: 310px;

        & + hr {
          margin: 0;
          border: none !important;
        }

        & + blockquote,
        & + * + blockquote {
          display: inline-block;
          width: $densearea-description-width;
          background-color: #f5f5f5;
          border: solid 1px #e9e9e9;
          border-radius: 4px;

          margin: 0;
          padding: 20px;

          p {
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;

            strong {
              font-size: 16px;
              font-weight: 500;
            }

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          & + p {
            $margin: 15px;
            display: inline-block;
            margin-bottom: 0;
            margin-left: $margin;
            vertical-align: top;

            max-width: calc(100% - #{$densearea-description-width + $margin * 2});

            > img {
              max-width: 100%;
            }
          }
        }
      }

      // process-desc-ul
      #process_desc {
        & + ul,
        & + p + ul {
          @include no-list-style();

          margin: 0;
          padding-left: 0;

          li + li {
            margin-top: 10px;
          }
        }
      }

      // process-ul
      $process-offset: 7.5px;
      #process {
        & + ul {
          @include no-list-style();

          $margin-between-indicator: 15px;
          $list-left-padding: 20px;
          $bullet-size: 10px;

          margin-top: 20px;
          padding-left: $list-left-padding + $margin-between-indicator + $process-offset;

          // &h3 {
          //   margin-top: 0;
          //   padding-left: $margin-between-indicator + $process-offset;
          // }

          li {
            position: relative;

            &:after {
              content: '';
              width: $bullet-size;
              height: $bullet-size;
              border-radius: $bullet-size;
              background-color: white;
              border: 3px solid #ccc;

              display: inline-block;
              position: absolute;
              left: -1 * ($list-left-padding + $margin-between-indicator) + $process-offset;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              z-index: 2;
            }
          }

          li + li {
            $width: 2px;

            &:before {
              content: '';
              display: inline-block;
              width: 2px;
              height: 60px;
              background-color: #ccc;

              position: absolute;
              left: -1 * ($list-left-padding + $margin-between-indicator) + $bullet-size / 2 + $width + $process-offset;
              top: -30px;
              z-index: 1;
            }
          }

          li:last-child:before {
            top: -40px;
          }

          & + p {
            margin-left: $content-left-padding;
          }
        }
      }

      h2#process {
        & + ul {
          margin-top: 0;
          padding-left: $content-left-padding + $process-offset + 1px;
        }
      }

      // required_documents-h3-ul
      $checkbox-size: 22px;
      $checkbox-padding: 12px;

      #required_documents,
      #required_document_e,
      #required_document_d,
      #required_document_f {
        & + ol,
        & + h3 + ol {
          list-style-type: none;
          padding-left: $content-left-padding + $checkbox-size + $checkbox-padding;
          margin-bottom: 48px;

          li {
            position: relative;

            &:before {
              content: '';
              background-image: url('/assets/img/pages/pricing/check.svg');
              width: $checkbox-size;
              height: $checkbox-size;
              background-size: 100%;
              background-position: center;
              background-repeat: no-repeat;
              display: inline-block;
              position: absolute;
              left: -1 * ($checkbox-size + $checkbox-padding);
              top: $checkbox-size / 4 - 2px;
            }
          }

          li + li {
            margin-top: 10px;
          }

          & + p {
            margin-top: -1 * $h3-bottom-margin;
            padding-left: $content-left-padding;
          }
        }
      }

      h2#--requireddocuments {
        & + ol {
          padding-left: $checkbox-size + $checkbox-padding;

          & + p {
            padding-left: 0;
          }
        }
      }

      // steps-ul
      #steps {
        & + ul {
          list-style-type: none;
          padding-left: 0;

          li {
            em {
              font-style: normal;
              font-weight: 700;
              margin-right: 10px;
            }
          }

          li + li {
            margin-top: 10px;
          }
        }
      }

      // article_what-blockquote
      #article_what {
        & + ol {
          margin-bottom: 24px;

          li + li {
            margin-top: 24px;
          }

          li {
            color: $text-color;

            blockquote {
              margin: 0;
              margin-top: 8px;
              @include subcontent();

              p {
                margin: 0;
              }
            }
          }

          & + p {
            position: relative;
            > a {
              @include find-icon();
            }
          }
        }
      }

      #find_link {
        & + ol + p,
        & + ul + p {
          position: relative;
          > a {
            @include find-icon();
          }
        }
      }
    }
  }

  .pricing-content.is-calculator-active {
    > * > #pricing {
      &,
      & + hr,
      & + p,
      & + p + hr {
        display: none !important;
        & + table,
        & + table + table {
          display: none !important;
        }
      }
    }
  }

  call-to-action-section {
    margin-top: $h2-content-between-margin;
    padding-top: 0;

    &,
    & .cta {
      padding-bottom: 0;
      background-color: white;
    }

    .cta {
      margin-bottom: -100px + 50px;
      border-top: 1px solid #e0e0e0;

      h1 {
        font-size: 25px;
        font-weight: 500;

        br {
          display: none;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 400;
      }

      a {
        display: block;

        &:before {
          display: none;
        }
      }
    }
  }
}
