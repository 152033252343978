@import '../../common/mixin';

#view-mobile .page-about {
  .background {
    @include background-image-default();
    background-image: url('../../assets/img/pages/about/banner.png');
    height: 200px;
    margin-top: -38px;
    .background-text {
      padding-top: 60px;

      > h1 {
        line-height: 1.3em;
        letter-spacing: 0.06em;
        margin-bottom: 5px;
      }

      > p {
        font-size: 18px;
      }
    }
  }

  .content {
    padding: 20px;
    font-size: 16px !important;
    line-height: 1.8em;

    .header {
      font-size: 20px;
    }

    > img {
      margin-left: -20px !important;
      width: calc(100% + 40px);
    }

    span.header {
      display: inline-block;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }

    p.large {
      line-height: 2em;
    }

    p.desktop {
      display: none;
    }

    p + p {
      margin-top: 5px;
    }

    p.force-new-line {
      margin: 3px 0;
    }

    ol,
    ul {
      padding-left: 20px;
      line-height: 1.8em;
      margin: 20px 0;

      > li {
        margin-bottom: 5px;

        > span {
          line-height: 1.8em;
        }
      }
    }

    ol > li {
      margin-bottom: 15px;
    }

    p > span {
      line-height: 1.8em;
    }
  }
}

.content-2 {
  margin: 20px 0px;
}
