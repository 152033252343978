@import '../../common/variable';
@import '../../common/mixin';

#view-mobile {
  .silver-text {
    color: #9c9c9c !important;
  }

  .red-text {
    font-size: 20px;
    margin-bottom: 20px;
    margin: 0;
    color: #d0021b;
  }

  color: #404041;

  h1,
  h2,
  h3 {
    font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;
  }

  .section {
    .container {
      h1,
      h2,
      h3,
      p,
      span {
        font-weight: 600;
        margin: 0;

        &.light {
          font-weight: 400;
        }
      }

      h2 {
        margin-bottom: 20px;
        font-size: 20px;
      }

      p {
        font-weight: 400;
      }
      h2.accent-red {
        font-size: 26px;
        margin-bottom: 30px;
      }

      p.top-desc {
        text-align: left;
        font-size: 16px;
        line-height: 35px;
        letter-spacing: -0.1px;
        font-weight: 400;
      }
    }

    &.gray {
      background-color: #f5f5f5;

      p {
        margin-top: 15px;
      }

      img {
        margin-left: -20px;
      }
    }

    &.process {
      .border {
        @include clearfix();

        padding: 30px 40px;
        border: 1px solid black;
        border-radius: 8px;

        table {
          table-layout: fixed;

          tr {
            td {
              padding: 5px 0;
              line-height: 25px;
              font-size: 16px;
            }

            td:last-child {
              padding-left: 15px;
              text-align: left;
            }
          }
        }
      }

      .process-wrapper {
        &.gray {
          .border {
            border-color: $gray-color-light2;
          }

          table {
            tr:nth-child(3) {
              td:first-child {
                padding-left: 20px;
              }

              td:last-child {
                padding-left: 15px;
              }
            }
          }
        }

        &.blue {
          margin-left: 20px;

          .border {
            border-color: $helpme-main-color;
          }
        }
      }
    }
  }
}

#view-mobile .page-comparison {
  h1,
  h2,
  h3 {
    font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;
  }

  .cta {
    h1 {
      margin-bottom: 20px;
    }

    h3 {
      font-weight: 400;
    }
  }

  .section {
    padding: 0;

    &.top {
      padding-top: 55px;
    }

    .container {
      padding: 30px 20px;

      > img {
        max-width: 310px;
        display: block;
        margin: 0 auto;
      }

      h2 {
        font-size: 16px;
        line-height: 1.6em;
        margin-bottom: 10px;
      }

      h1.accent-red {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    &.top,
    &.process {
      .container > h1 {
        font-size: 20px;
        margin-bottom: 25px;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        line-height: 30px;
        text-align: left;
      }
    }

    &.process {
      .container {
        padding: 30px;

        p,
        h3,
        h2,
        h1 {
          color: $text-color;
        }

        h3 {
          margin-top: 30px;
          margin-bottom: 10px;
          font-size: 16px;
        }

        p {
          text-align: left;
        }

        p.gray {
          font-size: 13px;
          line-height: 1.8em;
          color: #9b9b9b;
        }

        .border {
          background-color: white;
          width: 100%;
        }
      }

      .process-wrapper.blue {
        margin-left: 0;
      }
    }
  }
}
