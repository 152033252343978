.changeRegistrationSurveyMobile {
  //모바일
  padding: 30px 25px 50px;
  max-width: 100%;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
  .survey {
    position: relative;
    margin-top: 21px;
    padding: 36px 16px;
    border: 1px solid #dcdbdc;
    border-radius: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .surveyInfo {
      .surveyInfoTitle {
        margin: 0;
        color: #1d5cb8;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.8px;
        text-align: center;
      }
      p {
        margin: 0;
        color: #000;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.38;
        letter-spacing: -0.64px;
        .bold {
          color: #1d5cb8;
          font-weight: 400;
        }
      }
      p + p {
        margin-top: 8px;
      }
    }
    .form {
      margin-top: 36px;
      padding-top: 36px;
      border-top: 1px solid #dedede;
      .title {
        margin: 0;
        color: #1e1e22;
        font-size: 20px;
        font-weight: 400;
      }
      .formQnA {
        margin-top: 40px;
        &:nth-child(2) {
          margin-top: 28px;
        }
        .question {
          color: #1d5cb8;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: -0.64px;
          & + .inputText {
            margin-top: 16px;
          }
          .questionTag {
            position: relative;
            display: block;
            margin-top: 6px;
            margin-left: 8px;
            color: #999;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.33;
            letter-spacing: -0.3px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              left: -8px;
              top: 3px;
            }
          }
        }
        .questionExplain {
          position: relative;
          margin-top: 8px;
          margin-left: 8px;
          color: #999;
          font-size: 15px;
          font-weight: 400;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: -8px;
          }
        }
        .radio {
          margin-top: 14px;
          &:nth-child(2) {
            margin-top: 16px;
          }
        }
        .checkBox {
          margin-top: 14px;
        }
        .inputText {
          margin-top: 14px;
          width: 100%;
        }
        .etcInputText {
          margin-top: 10px;
          margin-left: 32px;
          width: calc(100% - 32px);
        }
        .textArea {
          margin-top: 14px;
          padding: 9px 12px;
          width: 100%;
          height: 87px;
          color: #3f3e43;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.38;
          &:focus {
            outline: none;
            border: 1px solid #626164;
          }
        }
      }
      .error {
        display: inline-block;
        margin-top: 8px;
        color: #eb4242;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .btnBox {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    .surveyBtn {
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: 0 24px;
      width: 100%;
      height: 60px;
      border: none;
      border-radius: 30px;
      background-color: #2852af;
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.88px;
      text-align: left;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 15px;
        right: 24px;
        width: 19px;
        height: 30px;
        background-image: url(/assets/img/icons/corpArrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

.changeRegistrationSurvey {
  //pc
  max-width: 886px;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.47;
    text-align: center;
  }
  .survey {
    position: relative;
    margin-top: 21px;
    padding: 43px 46px;
    min-width: 886px;
    border: 1px solid #dcdbdc;
    border-radius: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:after {
      position: absolute;
      top: 19px;
      right: 38px;
      display: inline-block;
      content: '';
      width: 149px;
      height: 209px;
      background-image: url('../../../../assets/img/pages/signup/hyo2.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .surveyInfo {
      position: relative;
      padding-left: 140px;
      .subTitle {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        color: #000;
        font-size: 21px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.42px;
      }
      p {
        margin: 0;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.39;
        letter-spacing: -0.7px;
        .bold {
          font-weight: 400;
          color: #1d5cb8;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 10px;
        li {
          position: relative;
          margin-top: 6px;
          margin-left: 8px;
          color: #333;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: -0.45px;
          &:before {
            content: '-';
            display: inline-block;
            position: absolute;
            top: 0;
            left: -8px;
          }
        }
      }
    }
    .form {
      position: relative;
      margin-top: 50px;
      padding-top: 50px;
      padding-left: 140px;
      border-top: 1px solid #dcdbdc;
      &:nth-child(2) {
        margin-top: 35px;
      }
      .title {
        position: absolute;
        left: 0;
        top: 46px;
        margin: 0;
        color: #000;
        font-size: 21px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.42px;
        .explain {
          font-size: 14px;
        }
      }
      .formQnA {
        margin-top: 38px;
        &:nth-child(2) {
          margin-top: 0;
        }
        .question {
          color: #1d5cb8;
          font-size: 18px;
          font-weight: 500;
          & + .inputText {
            margin-top: 16px;
          }
          .questionTag {
            position: relative;
            display: inline-block;
            margin-left: 22px;
            color: #999;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.87;
            letter-spacing: -0.3px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              left: -10px;
              top: 3px;
            }
          }
        }
        .questionExplain {
          position: relative;
          margin-top: 4px;
          margin-left: 10px;
          max-width: 514px;
          color: #838384;
          font-weight: 400;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            left: -10px;
            top: 3px;
          }
        }
        .radio {
          margin-top: 14px;
        }
        .checkBox {
          margin-top: 14px;
        }
        .inputText {
          margin-top: 14px;
          width: 514px;
        }
        .etcInputText {
          margin-top: 8px;
          margin-left: 23px;
          width: 210px;
        }
        .textArea {
          margin-top: 14px;
          padding: 12px 14px;
          width: 514px;
          height: 85px;
          border: 1px solid #b8b7ba;
          border-radius: 3px;
          color: #3f3e43;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.38;
          &:focus {
            outline: none;
            border: 1px solid #626164;
          }
        }
      }
      .error {
        display: inline-block;
        margin-top: 4px;
        color: #eb4242;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
  .btnBox {
    margin-top: 46px;
    margin-bottom: 60px;
    text-align: center;
    .surveyBtn {
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: 0 30px;
      width: 215px;
      height: 48px;
      border: none;
      border-radius: 24px;
      background-color: #2852af;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 27px;
        width: 15px;
        height: 20px;
        background-image: url(/assets/img/icons/corpArrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
