.form__content h1,
.form__content h2,
.form__content h3,
.form__content h4,
.form__content h5,
.form__content h6 {
  margin-top: 30px;
  line-height: 1.4em;
}

.form__content h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-bottom: 5px;
}

.form__content h2 {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.form__content p {
  font-size: 16px;
  line-height: 1.4em;
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 5px;
}

/* TODO: H3 추가 */

.form__content h1:first-child,
.form__content h2:first-child,
.form__content p:first-child {
  margin-top: 0;
}

.form__item:last-child,
.form__item div:last-child {
  margin-bottom: 0;
}
