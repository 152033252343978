.stockOptionMobile {
  //모바일
  margin-top: 28px;
  .stockOptionBox {
    .boxTitle {
      color: #1d5cb8;
      font-size: 18px;
      font-weight: 400;
    }
    ol {
      margin: 0;
      padding: 0;
      li {
        margin-top: 10px;
        list-style-position: inside;
        color: #3f3e43;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.38;
        letter-spacing: -0.64px;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            position: relative;
            margin-top: 4px;
            margin-left: 8px;
            color: #999;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.3px;
            &:before {
              content: '-';
              display: inline-block;
              position: absolute;
              top: 0;
              left: -8px;
            }
          }
        }
      }
    }
  }
  .stockOptionGuide {
    margin-top: 36px;
    .title {
      color: #1d5cb8;
      font-size: 18px;
      font-weight: 400;
    }
    .aLink {
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-top: 6px;
      color: #3f3e43;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.38;
      letter-spacing: -0.75px;
      &:nth-child(2) {
        margin-top: 10px;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: -23px;
        top: 2px;
        width: 19px;
        height: 19px;
        background-image: url(/assets/img/icons/icon_aLink.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .stockOptionAuthorize {
    margin-top: 12px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        position: relative;
        margin-left: 8px;
        margin-top: 6px;
        color: #3f3e43;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.3px;
        &:before {
          content: '-';
          display: inline-block;
          position: absolute;
          top: 0;
          left: -8px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.stockOption {
  //pc
  .stockOptionBox {
    padding: 24px 30px;
    width: 514px;
    border: 1px solid #8f8f8f;
    border-radius: 10px;
    background-color: #fff;
    .boxTitle {
      color: #333;
      font-size: 16px;
      font-weight: 400;
    }
    ol {
      margin: 0;
      padding: 0;
      li {
        margin-top: 11px;
        margin-left: 16px;
        color: #1d5cb8;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.3px;
        &:first-child {
          margin-top: 8px;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            position: relative;
            margin: 0;
            padding: 0;
            margin-top: 5px;
            padding-left: 8px;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.43;
            letter-spacing: -0.42px;
            &:before {
              content: '-';
              display: inline-block;
              position: absolute;
              top: 0;
              left: -3px;
            }
          }
        }
      }
    }
  }
  .stockOptionGuide {
    margin-top: 35px;
    .title {
      color: #1d5cb8;
      font-size: 18px;
      font-weight: 400;
    }
    .aLink {
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-top: 7px;
      color: #3f3e43;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.38;
      &:nth-child(2) {
        margin-top: 17px;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: -24px;
        top: 2px;
        width: 18px;
        height: 18px;
        background-image: url(/assets/img/icons/icon_aLink.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .stockOptionAuthorize {
    margin-top: 14px;
    margin-bottom: 18px;
    padding: 24px 30px;
    width: 514px;
    border: 1px solid #b8b7ba;
    border-radius: 3px;
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        margin-left: 9px;
        margin-top: 8px;
        color: #333;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.45px;
        &:before {
          content: '-';
          display: inline-block;
          position: absolute;
          top: 0;
          left: -9px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
