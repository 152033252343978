.estimateDashboardMobile {
  //mobile
  padding: 16px 25px 50px;
  .estimateStart {
    padding-bottom: 16px;
    .mainTitle {
      margin: 0;
      color: #404040;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
  .contentBox {
    padding-top: 16px;
    &.ongoing {
      border-top: 1px solid #f5f4f4;
    }
    .title {
      margin: 0;
      color: #1e1e22;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.4px;
    }
    .ongoingBtn {
      cursor: pointer;
      display: block;
      margin-top: 8px;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      background-color: #fff;
      text-decoration: none;
      padding: 16px;
      transition: all 0.2s ease;
      &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid #012870;
      }
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
      .top {
        position: relative;
        text-align: left;
        min-height: 64px;
        padding-left: 76px;
        margin-bottom: 12px;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 64px;
          height: 64px;
          background-image: url(../../../assets/img/pages/main/company.svg);
          background-size: cover;
          background-repeat: no-repeat;
        }
        .ongoing {
          color: #404040;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          padding-left: 32px;
          background-image: url(../../../assets/img/pages/main/newTag.svg);
          background-repeat: no-repeat;
          padding-top: 2px;
          background-position: left top 5px;
        }
        .companyName {
          color: #012870;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.32px;
          margin-top: 2px;
        }
      }
      .bottom {
        height: 44px;
        text-align: center;
        border-radius: 4px;
        border: none;
        line-height: 44px;
        background-color: #012870;
        span {
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          background-image: url('/assets/img/pages/dashboard/bigRightArrow.svg');
          padding-right: 20px;
          background-repeat: no-repeat;
          background-position: right top 2px;
        }
      }
    }
    .ongoingBtnExplain {
      position: relative;
      margin-top: 8px;
      margin-left: 10px;
      color: #999999;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: -10px;
      }
    }
    .estimateBtnBox {
      display: flex;
      flex-direction: column;
      .estimateBtn {
        cursor: pointer;
        margin-top: 12px;
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #d8d8d8;
        background-color: #fff;
        transition: all 0.2s ease;
        &:first-child {
          margin-top: 8px;
        }
        &:hover {
          &.new {
            border: solid 1px #006ac3;
          }
          &.personal {
            border: solid 1px #e25700;
          }
          &.change {
            border: solid 1px #00865d;
          }
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        }
        &:active {
          position: relative;
          top: 1px;
          left: 1px;
        }
        .top {
          position: relative;
          text-align: left;
          padding-left: 76px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 64px;
            height: 64px;
            background-size: cover;
            background-repeat: no-repeat;
          }
          &.new {
            &:before {
              background-image: url(../../../assets/img/pages/main/new.svg);
            }
            .title {
              color: #006ac3;
            }
          }
          &.personal {
            &:before {
              background-image: url(../../../assets/img/pages/main/personal.svg);
            }
            .title {
              color: #e25700;
            }
          }
          &.change {
            &:before {
              background-image: url(../../../assets/img/pages/main/change.svg);
            }
            .title {
              color: #00865d;
            }
          }
          .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.32px;
          }
          .content {
            margin-top: 3px;
            color: #404040;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
        .bottom {
          height: 44px;
          text-align: center;
          border-radius: 4px;
          border: none;
          line-height: 44px;
          width: 100%;
          margin-top: 12px;
          span {
            vertical-align: top;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            background-image: url('/assets/img/pages/dashboard/bigRightArrow.svg');
            padding-right: 20px;
            background-repeat: no-repeat;
            background-position: right top 2px;
          }
          &.new {
            background-color: #006ac3;
          }
          &.personal {
            background-color: #e25700;
          }
          &.change {
            background-color: #00865d;
          }
        }
      }
    }
    .incorporationImg {
      margin-top: 24px;
      width: 100%;
    }
    .incorporationImgExplain {
      color: #858a95;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
      .bold {
        color: #000;
        font-weight: 400;
        letter-spacing: -0.28px;
      }
    }
    .incorporationTime {
      margin-top: 20px;
      color: #404041;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6;
      .bold {
        font-weight: 400;
      }
    }
    .incorporationTimeExplain {
      position: relative;
      margin-top: 8px;
      margin-left: 9px;
      color: #999999;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: -9px;
      }
    }
    .searchBox {
      position: relative;
      margin-top: 24px;
      width: 100%;
      height: 48px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 16px;
        left: 7px;
        width: 17px;
        height: 17px;
        background-image: url(../../../assets/img/pages/main/searchIcon_mobile.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .search {
        padding: 16px 28px;
        width: 85%;
        height: 100%;
        color: #404041;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: -0.32px;
        border: 1px solid #1d5cb8;
        border-right: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        vertical-align: top;
        &::placeholder {
          color: #858a95;
        }
      }
      .searchBtn {
        cursor: pointer;
        width: 15%;
        height: 100%;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.4px;
        border: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #1d5cb8;
        vertical-align: top;
      }
    }
    .linkBox {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 40%;
      margin-top: 20px;
      width: 100%;
      .infoLink {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(-n + 2) {
          margin-top: 0;
        }
        &:last-child {
          width: 100%;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-top: 10px;
        padding: 6px 18px;
        width: calc((100% - 10px) / 2);
        height: 52px;
        background-color: #fff;
        border: 1px solid #c2c2c2;
        border-radius: 10px;
        color: #474750;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.5px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
  input:focus {
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.estimateDashboard {
  //pc
  margin: 98px auto;
  padding: 50px 46px 60px;
  width: 886px;
  border-radius: 10px;
  border: 1px solid #dcdbdc;
  background-color: #fff;
  .estimateStart {
    position: relative;
    padding-bottom: 89px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -18px;
      right: -10px;
      width: 277px;
      height: 204px;
      background-image: url(../../../assets/img/pages/main/hyo_logo.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
    .mainTitle {
      margin: 0;
      color: #404040;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.46;
    }
    .explain {
      margin-top: 16px;
      color: #404040;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
  .contentBox {
    padding: 40px 0 50px;
    border-top: 1px solid #dcdbdc;
    &:last-child {
      padding-bottom: 0;
    }
    .title {
      margin: 0;
      color: #000;
      font-size: 21px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: -0.84px;
    }
    .ongoingBtn {
      cursor: pointer;
      margin-top: 24px;
      display: inline-block;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      background-color: #fff;
      text-decoration: none;
      padding: 16px;
      transition: all 0.2s ease;
      &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid #012870;
      }
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
      .top {
        position: relative;
        text-align: left;
        min-height: 64px;
        padding-left: 76px;
        margin-bottom: 12px;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 64px;
          height: 64px;
          background-image: url(../../../assets/img/pages/main/company.svg);
          background-size: cover;
          background-repeat: no-repeat;
        }
        .ongoing {
          color: #404040;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-left: 32px;
          background-image: url(../../../assets/img/pages/main/newTag.svg);
          background-repeat: no-repeat;
          background-position: left top 8px;
          padding-top: 5px;
        }
        .companyName {
          color: #012870;
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.48px;
          margin-top: 2px;
        }
      }
      .bottom {
        height: 44px;
        text-align: center;
        border-radius: 4px;
        border: none;
        line-height: 44px;
        width: 492px;
        background-color: #012870;
        span {
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          background-image: url('/assets/img/pages/dashboard/bigRightArrow.svg');
          padding-right: 20px;
          background-repeat: no-repeat;
          background-position: right top 2px;
        }
      }
    }
    .ongoingBtnExplain {
      position: relative;
      margin-top: 8px;
      margin-left: 10px;
      color: #999999;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.87;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: -10px;
      }
    }
    .estimateBtnBox {
      display: flex;
      justify-content: space-between;
      .estimateBtn {
        cursor: pointer;
        margin-top: 24px;
        padding: 16px;
        width: 254px;
        border-radius: 8px;
        border: 1px solid #d8d8d8;
        background-color: #fff;
        transition: all 0.2s ease;
        &:hover {
          &.new {
            border: solid 1px #006ac3;
          }
          &.personal {
            border: solid 1px #e25700;
          }
          &.change {
            border: solid 1px #00865d;
          }
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        }
        &:active {
          position: relative;
          top: 1px;
          left: 1px;
        }
        .top {
          position: relative;
          text-align: left;
          padding-top: 76px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 64px;
            height: 64px;
            background-size: cover;
            background-repeat: no-repeat;
          }
          &.new {
            &:before {
              background-image: url(../../../assets/img/pages/main/new.svg);
            }
            .title {
              color: #006ac3;
            }
          }
          &.personal {
            &:before {
              background-image: url(../../../assets/img/pages/main/personal.svg);
            }
            .title {
              color: #e25700;
            }
          }
          &.change {
            &:before {
              background-image: url(../../../assets/img/pages/main/change.svg);
            }
            .title {
              color: #00865d;
            }
          }
          .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.4px;
          }
          .content {
            margin-top: 8px;
            color: #404040;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
          }
        }
        .bottom {
          height: 44px;
          text-align: center;
          border-radius: 4px;
          border: none;
          line-height: 44px;
          width: 100%;
          margin-top: 24px;
          span {
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            background-image: url('/assets/img/pages/dashboard/bigRightArrow.svg');
            padding-right: 20px;
            background-repeat: no-repeat;
            background-position: right top 2px;
          }
          &.new {
            background-color: #006ac3;
          }
          &.personal {
            background-color: #e25700;
          }
          &.change {
            background-color: #00865d;
          }
        }
      }
    }
    .incorporationImg {
      margin-top: 24px;
      width: 794px;
    }
    .incorporationImgExplain {
      color: #858a95;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
      .bold {
        color: #000;
        font-weight: 400;
        letter-spacing: -0.28px;
      }
    }
    .incorporationTime {
      margin-top: 24px;
      color: #404041;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6;
      .bold {
        color: #1e1e22;
        font-weight: 500;
      }
    }
    .incorporationTimeExplain {
      position: relative;
      margin-top: 5px;
      margin-left: 9px;
      color: #858a95;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: -9px;
      }
    }
    .searchBox {
      position: relative;
      margin-top: 24px;
      width: 100%;
      height: 60px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 16px;
        width: 30px;
        height: 30px;
        background-image: url(../../../assets/img/pages/main/searchIcon.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .search {
        padding: 18px 46px;
        width: 85%;
        height: 100%;
        color: #404041;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: -0.32px;
        border: 1px solid #1d5cb8;
        border-right: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        &::placeholder {
          color: #858a95;
        }
      }
      .searchBtn {
        cursor: pointer;
        position: relative;
        top: 1px;
        width: 15%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.4px;
        border: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #1d5cb8;
      }
    }
    .linkBox {
      margin-top: 30px;
      width: 100%;
      .infoLink {
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &:nth-child(n + 4) {
          margin-top: 16px;
        }
        &:last-child {
          width: 100%;
        }
        &:hover {
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
          border: 1px solid #767681;
          transition: all 0.2s ease;
        }
        &:active {
          position: relative;
          top: 1px;
          left: 1px;
        }
        display: inline-block;
        margin-left: 24px;
        padding: 15px 33px;
        width: 248px;
        height: 50px;
        background-color: #fff;
        border: 1px solid #c2c2c2;
        border-radius: 10px;
        color: #474750;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: -0.32px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
  input:focus {
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}
