.changeRegistrationSuccessMobile {
  padding: 30px 25px 50px;
  max-width: 100%;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
  .success {
    position: relative;
    margin-top: 16px;
    padding: 36px 16px;
    border: 1px solid #dcdbdc;
    border-radius: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .successContent {
      margin-top: 36px;
      padding-top: 36px;
      border-top: 1px solid #dcdbdc;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        .subTitle {
          top: 0;
        }
      }
      .subTitle {
        margin: 0;
        color: #1d5cb8;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.8px;
        text-align: center;
      }
      p {
        margin: 0;
      }
      .successRegistration {
        position: relative;
        margin-left: 8px;
        color: #333;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        &:before {
          content: '-';
          display: inline-block;
          position: absolute;
          left: -8px;
          top: 0;
        }
        &:nth-child(2) {
          margin-top: 16px;
        }
      }
      .processInfo {
        margin-top: 7px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.38;
        letter-spacing: -0.32px;
        &:nth-child(2) {
          margin-top: 16px;
        }
        .bold {
          font-weight: 400;
        }
        .aLink {
          cursor: pointer;
          color: #1d5cb8;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.38;
          letter-spacing: -0.32px;
          text-decoration: underline;
        }
      }
      .video {
        display: inline-block;
        margin-top: 28px;
        width: 100%;
      }
    }
  }
}

.changeRegistrationSuccess {
  //pc
  max-width: 886px;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
  }
  .success {
    position: relative;
    margin-top: 21px;
    padding: 43px 46px;
    min-width: 886px;
    border: 1px solid #dcdbdc;
    border-radius: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .successContent {
      position: relative;
      margin-top: 47px;
      padding-top: 47px;
      padding-left: 140px;
      min-height: 136px;
      border-top: 1px solid #dcdbdc;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        .subTitle {
          top: 0;
        }
      }
      &:nth-child(2) {
        &:after {
          position: absolute;
          top: -203px;
          right: 13px;
          display: inline-block;
          content: '';
          width: 186px;
          height: 203px;
          background-image: url('../../../../assets/img/pages/signup/sang.png');
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .subTitle {
        position: absolute;
        left: 0;
        top: 47px;
        margin: 0;
        color: #000;
        font-size: 21px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.84px;
      }
      p {
        margin: 0;
        max-width: 521px;
      }
      .successAlert {
        color: #1d5cb8;
        font-size: 19px;
        font-weight: 400;
      }
      .successRegistration {
        position: relative;
        margin-left: 8px;
        margin-top: 6px;
        color: #333;
        font-size: 15px;
        font-weight: 400;
        &:before {
          content: '-';
          display: inline-block;
          position: absolute;
          left: -8px;
          top: 0;
        }
        &:nth-child(3) {
          margin-top: 14px;
        }
      }
      .processInfo {
        margin-top: 10px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.38;
        letter-spacing: -0.32px;
        &:nth-child(2) {
          margin-top: 0;
        }
        .bold {
          font-weight: 400;
        }
        .aLink {
          cursor: pointer;
          color: #1d5cb8;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.38;
          letter-spacing: -0.32px;
          text-decoration: underline;
        }
      }
      .video {
        display: inline-block;
        margin-top: 32px;
        width: 514px;
        height: 331px;
      }
    }
  }
}
