@import './variable';

.rounded-input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #ccc;
  font-size: 16px;
  box-sizing: border-box;

  // iOS trick
  padding: 0;
  padding-left: 15px;
}

.inline-block-inner {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

@mixin background-image-default() {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  .background-text {
    text-align: center;
    color: white;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

    > h1 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    > p {
      margin: 0 auto;
      color: $gray-color-light1;
      font-size: 16px;
      max-width: 240px;
      font-weight: 400;
      line-height: 1.7em;
    }
  }
}

// mixins
@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file + '@2x.' + $type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
    }
  }
}

@mixin pseudo-border-horizontal($color) {
  width: 100%;
  height: 1px;
  background-color: $color;
  display: block;
}

@mixin pseudo-border-vertical($color) {
  height: 100%;
  width: 1px;
  background-color: $color;
  display: inline-block;
}

@mixin normalize-appearance($color: white) {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: $color;

  &:focus {
    outline: none;
  }
}

@mixin clearfix() {
  content: '';
  display: table;
  clear: both;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin helpme-checkbox($mobile: false) {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid #b8b9bb;
  background-color: #fff;
  vertical-align: top;
  margin: 10px;
  width: 20px;
  height: 20px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;

  &:checked {
    border-color: $helpme-main-color-darken;
    background-image: url(/assets/img/icons/checked.svg);
  }

  &:focus {
    border-color: $helpme-main-color-darken;
  }

  &::-ms-expand {
    display: none;
  }

  @if $mobile == false {
    line-height: 30px;
  } @else {
    line-height: 1em;
  }
}

@mixin image-buttons($has-image) {
  .image-buttons {
    padding: 0 40px;
    display: flex;
    flex-direction: column;

    .image-button {
      @include normalize-appearance();
      padding: 1px;

      &:hover {
        cursor: pointer;
        padding: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #ccc;

      span {
        font-size: 16px;
        font-weight: 400;

        br {
          display: none;
        }
      }

      span.radio {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #b8b9bb;
        background-color: white;

        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        margin-left: 25px;
        margin-right: 25px;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $helpme-main-color-darken;
          // display: inline-block;
          display: none;
        }
      }

      img {
        width: 38px;
        margin-right: 25px;
      }

      img[alt='button-reg-modify'] {
        width: 40px;
        position: relative;
        right: -4px;
        margin-right: 18px;
      }

      &:hover {
        border: solid 2px #00b4e3;
      }

      &:active,
      &.active {
        background-color: #dcf1fd;

        span {
          font-weight: 500;
        }

        span.radio {
          border-color: $helpme-main-color-darken;

          &:before {
            display: inline-block;
          }
        }
      }

      @if $has-image == true {
        height: 90px;
      } @else {
        padding: 15px 1px;

        &:hover {
          padding: 14px 0;
        }

        span.radio {
          margin-left: 20px;
          margin-right: 10px;
        }
      }
    }

    .image-button + .image-button {
      margin-top: 10px;
    }
  }

  .image-buttons + .form-group {
    margin-bottom: 10px;

    label.error {
      margin-top: 10px;
    }
  }
}

@mixin helpme-form($mobile) {
  .form-group {
    margin-bottom: 30px;
    line-height: 1.6em;

    label.error {
      color: #eb2631;
      display: block;
      margin-top: 5px;
    }

    .form-label {
      font-size: 16px;
      font-weight: 400;

      strong {
        font-weight: 500;
      }
    }

    .form-label-sub {
      font-size: 14px;
      color: #9c9c9c;
      margin-top: 5px;
    }

    textarea {
      resize: none !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-right: 15px !important;

      min-height: 40px;
    }

    input.input,
    textarea.input {
      @extend .rounded-input;
      margin-top: 10px;

      &.short {
        max-width: 260px;
      }

      &.code {
        max-width: 90px;
        text-align: center;
        text-transform: uppercase;
        padding-left: 0;
      }

      & + .dash {
        display: inline-block;
        width: 15px;
        text-align: center;
      }

      &:focus {
        outline: none;
        border-color: $helpme-main-color;
      }

      &.submit-triggered.ng-invalid {
        border-color: #eb2631;
      }
    }

    input[type='checkbox'] {
      @include helpme-checkbox($mobile);
      margin-left: 0;

      margin-top: 7.5px;
      margin-bottom: 7.5px;
    }

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      border: none;

      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        padding: 7px;
        border-radius: 12px;
        border: 1px solid $gray-color-light2;
        margin-top: -2px;

        box-sizing: border-box;
      }

      &:focus {
        outline: none;
      }

      &:checked {
        background-color: white !important;

        &:before {
          border-color: $helpme-main-color;
          background-color: $helpme-main-color;

          background-clip: content-box;
        }
      }

      & + span {
        padding-left: 15px;
      }
    }

    label[for^='checkbox'] {
      display: block;
      margin-top: 5px;

      span.light {
        font-weight: 400;
      }

      > span {
        display: inline-block;
        font-size: 16px;
        line-height: 35px;

        &:hover {
          cursor: pointer;
        }
      }

      &[for='checkbox-etc'] {
        input[type='checkbox'] {
          margin-top: 10px;
        }

        input.input {
          display: inline-block;
          max-width: 223px;
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }

    .form-label + label {
      margin-top: 10px;
    }
  }
}

@mixin mobile-button() {
  font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;

  appearance: none;
  -webkit-appearance: none;
  border: 1px solid $helpme-main-color;
  background-color: $helpme-main-color;
  font-weight: 500;
  border-radius: 4px;

  padding: 0;
  width: 100%;

  &,
  & > button {
    color: white;
    line-height: 47px;
    height: 48px;
    font-size: 20px;
  }

  > a {
    @extend .inline-block-inner;
    color: white;
  }

  &:focus,
  &:active {
    background-color: $helpme-main-color-darken;
    border-color: $helpme-main-color-darken;
    outline: none;
  }

  &.inverse {
    border-color: $helpme-main-color;
    color: $helpme-main-color;
    background-color: white;

    > a {
      color: $helpme-main-color;
    }
  }

  &.inverse:focus,
  &.inverse:active {
    border-color: $helpme-main-color-darken;
    color: $helpme-main-color-darken;

    > a {
      color: $helpme-main-color-darken;
    }
  }

  &.disabled,
  &[disabled] {
    background-color: $gray-color-light2;
    border-color: $gray-color-light2;
  }

  &.inverse.disabled,
  &.inverse[disabled] {
    border-color: $gray-color-light2;
    color: $gray-color-light2;
  }
}

@mixin round-arrow-cta($font-size: 22px) {
  border-radius: $call-to-action-radius;

  &:after {
    content: '';

    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/img/icons/arrow-simple-right.svg);
    background-size: 100%;
    width: $font-size / 23 * 13;
    height: $font-size;
    display: inline-block;
    vertical-align: middle;

    position: relative;
    top: -1px;
    left: 15px;
  }
}
