$text-color: #404041;
$helpme-main-color: #00b4e3;
$helpme-main-color-darken: #0086b8;
$border-color: #b8b9bb;

$gray-color-light1: #f0f0f0;
$gray-color-light2: #ccc;
$gray-color-light3: #9c9c9c;

$info-bg-color: #d9edf7;
$mypage-border-color: #00749f;
$red-accent-color: #d0021b;

$alert-text-color: #ff4040;
$alert-bg-color: #f9d3cf;
$alert-border-color: #f3a69e;
$warning-text-color: #cc8411;
$warning-bg-color: #fce7c4;
$warning-border-color: #f9ce89;

$call-to-action-radius: 999px;
