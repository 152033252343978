@import '../../common/mixin';

.pricing-calculator--desktop {
  input {
    -webkit-appearance: none;
  }

  display: block;

  $group-margin: 200px;

  .pricing-meta {
    // 16px is margin bottom of h3
    padding-top: 24px - 16px;
    // 20px is margin bottom of p
    padding-bottom: 48px - 20px;
    font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;
    font-weight: 400;
  }

  .pricing-meta > p {
    color: #9b9b9b;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 14px;
  }

  .dense-region-toggle-button {
    @include normalize-appearance();
    width: 30px;
    height: 30px;
    background-image: url(/assets/img/pages/pricing/q.svg);
    background-size: 100%;
    margin-left: 25px;
    vertical-align: middle;
    font-size: 0;

    &:hover {
      cursor: pointer;
    }
  }

  $explanation-box-width: 340px;
  .dense-region-explanation {
    position: absolute;
    right: -1 * ($explanation-box-width - 140px);
    top: -25px;

    width: $explanation-box-width;
    padding: 25px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e8e8e8;
    border-radius: 12px;
    z-index: 5;

    font-size: 14px;
    line-height: 24px;

    > h3 {
      font-size: 22px;
      color: #b3b3b3;
      line-height: 1em;
      margin: 0;
      margin-bottom: 25px;
    }

    p > strong {
      font-size: 15px;
      font-weight: 600;
    }

    p:last-child {
      margin-bottom: 0;
    }

    $size: 25px;

    &:before {
      content: '';
      width: $size;
      height: $size;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #e8e8e8;

      position: absolute;
      top: 30px;
      left: -1 * $size / 2;

      transform: rotate(45deg);

      background-color: white;
    }

    &:after {
      content: '';
      width: $size * 0.8;
      height: $size * 0.8 * 2 + 2px;

      position: absolute;
      top: 22px;
      left: 0;

      background-color: white;
    }
  }

  button:not(.dense-region-toggle-button) {
    @include normalize-appearance();
    @include round-arrow-cta(18px);

    width: 100%;
    height: 50px;
    border: none;

    color: white;
    background-color: $helpme-main-color;
    font-size: 17px;

    &:hover,
    &:focus,
    &:active {
      background-color: $helpme-main-color-darken;
      border-color: $helpme-main-color-darken;
      cursor: pointer;
    }
  }

  > p,
  .input-group .input-group-content > p {
    font-size: 13px;

    &:last-child {
      margin-bottom: 35px;
    }
  }

  .input-group + .input-group {
    margin-top: 32px;
  }

  .input-group {
    font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    &.top-align {
      align-items: flex-start;
    }

    > span {
      min-width: $group-margin;
      line-height: 24px;
      font-size: 16px;

      &.vertical-one-line {
        margin-top: 8px;
      }
    }

    .input-group-content {
      width: 100%;
      position: relative;

      input.input,
      button {
        max-width: 400px;
      }

      .result {
        height: 24px;
        margin-top: 10px;
        margin-bottom: 0;
        padding-left: 5px;

        line-height: 1em;

        font-size: 14px;
        color: #9c9c9c;

        &.error {
          color: #eb4d59;
        }
      }

      > img {
        max-width: 100%;
        margin-top: 20px;
      }

      label {
        line-height: 40px;
        display: flex;
        align-items: center;
        height: 100%;
        color: #6b6b6b;

        input[type='checkbox'] {
          @include helpme-checkbox(false);
          float: none;
          margin-left: 0;
        }
      }

      input.input {
        width: 100%;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 40px;
        padding: 0 15px;
        font-size: 16px;

        &:focus {
          outline: none;
          border-color: $helpme-main-color;
        }
      }

      &.radio-group {
        text-align: right;

        label + label {
          margin-top: 4px;
        }

        label {
          font-size: 16px;
          color: $text-color;
          width: 100%;
          text-align: left;

          height: 24px;

          display: flex;
          flex-direction: row;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }

          input[type='radio'] {
            appearance: none;

            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 1px solid #b8b9bb;
            background-color: white;

            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            &:before {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background-color: $helpme-main-color-darken;
              // display: inline-block;
              display: none;
            }

            &:active,
            &:focus,
            &:checked {
              border-color: $helpme-main-color-darken;
            }

            &:checked {
              outline: none;

              &:before {
                display: inline-block;
              }
            }
          }
        }

        label + label {
          margin-top: 10px;
        }
      }

      &.pad {
        padding: 12px 0;
      }
    }

    &:last-child {
      margin-top: 25px;
    }
  }

  &.pad {
    p {
      margin-left: 20px;
    }
  }
}

.pricing-calculator--mobile {
  display: block;
  font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;

  input {
    -webkit-appearance: none;
  }

  $pricing-meta-padding: 25px;
  // 20px: paragraph padding
  $pricing-meta-padding-bottom: 40px - 20px;

  .pricing-meta {
    padding-top: $pricing-meta-padding;
    padding-bottom: $pricing-meta-padding-bottom;
  }

  .pricing-meta > p {
    color: #9b9b9b;
    margin-top: $pricing-meta-padding * -1;
    margin-bottom: 40px;
    font-size: 14px;
  }

  .button {
    @include normalize-appearance();
    @include round-arrow-cta();
    background-color: $helpme-main-color;
    color: white;

    width: 100%;
    height: 50px;

    font-size: 16px;
  }

  $toggle-button-offset: 45px;
  $dense-region-button-font-size: 14px;
  $dense-region-button-padding: 24px;
  $dense-region-button-height: $dense-region-button-padding * 2 + $dense-region-button-font-size - 10px;

  .dense-region-toggle-button {
    @include normalize-appearance();
    display: block;

    height: $dense-region-button-height;
    line-height: $dense-region-button-height;
    font-size: $dense-region-button-font-size;
    width: 100%;
    text-align: center;
    text-decoration: underline;

    position: relative;
    top: $toggle-button-offset;

    &:after {
      content: '';
      background-image: url(/assets/img/icons/caret-down-gray.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 18px;
      height: 10px;
      display: inline-block;

      vertical-align: middle;
      margin-left: 6px;
    }
  }

  .dense-region-explanation-container {
    label {
      height: 46px;
    }

    label.active {
      .dense-region-toggle-button:after {
        transform: rotate(180deg);
      }
    }
  }

  .dense-region-explanation-container-after {
    margin-top: $dense-region-button-height !important;
  }

  .dense-region-explanation {
    position: relative;
    top: $toggle-button-offset;
    margin-bottom: $toggle-button-offset + 10px;

    padding: 20px;
    border: solid 1px #e8e8e8;
    border-radius: 4px;
    z-index: 5;
    font-size: 14px;
    line-height: 24px;

    > h3 {
      font-size: 18px;
      color: #b3b3b3;
      line-height: 1em;
      margin: 0;
      margin-bottom: 25px;
    }

    p > strong {
      font-size: 15px;
      font-weight: 600;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  > p,
  .input-group .input-group-content > p {
    font-size: 13px;

    &:last-child {
      margin-bottom: 35px;
    }
  }

  .input-group + .input-group {
    margin-top: 24px + 24px;
  }

  .input-group {
    position: relative;

    > span {
      position: absolute;
      top: -1 * (10px + 14px);
      left: 0;
      width: 100%;
      line-height: 1em;

      br {
        display: none;
      }
    }

    .input-group-content {
      width: 100%;

      .result {
        margin-top: 8px;
        margin-bottom: 0;
        padding-right: 10px;
        line-height: 1em;

        color: #9c9c9c;

        text-align: right;

        &.error {
          color: #eb4d59;
        }
      }

      $input-height: 46px;
      $input-border: 1px solid #ccc;

      label {
        line-height: $input-height;
        border: $input-border;
        border-radius: 4px;
        display: flex;

        font-size: 0;

        &:active {
          background-color: rgba(0, 0, 0, 0.05);
        }

        input[type='checkbox'] {
          position: absolute;
          top: 11px;
          left: 0;

          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          appearance: none;
          background-color: transparent;
          color: transparent;
          border: none;

          &:before {
            display: inline-block;
            font-size: 14px;
            min-width: 80vw;
            color: $text-color;

            position: absolute;
            left: 45px;
            top: 5px;
          }

          &:after {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            left: 10px;

            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
          }

          & {
            &:before {
              content: '과밀억제권역에 속하면 체크해주세요.';
            }

            &:after {
              background-image: url(/assets/img/icons/checkoff.svg);
            }
          }

          &:checked {
            &:before {
              content: '과밀억제권역에 속하면 체크해주세요.';
            }

            &:after {
              background-image: url(/assets/img/icons/checkon.svg);
            }
          }
        }
      }

      input.input {
        width: 100%;
        background-color: white;
        height: $input-height;
        border: $input-border;
        padding: 0 15px;
        font-size: 18px;
        color: $helpme-main-color-darken;
        border-radius: 4px;

        text-align: right;
        -webkit-appearance: none;

        &:focus {
          outline: none;
          border-color: $helpme-main-color;
        }
      }

      &.radio-group {
        text-align: right;
        margin-top: 10px;

        label + label {
          margin-top: 8px;
        }

        label {
          font-size: 14px;
          color: $text-color;
          width: 100%;
          text-align: left;

          padding: 0 15px;

          display: flex;
          flex-direction: row;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }

          &.checked {
            background-color: #dcf1fd;
          }

          input[type='radio'] {
            appearance: none;

            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 1px solid #b8b9bb;
            background-color: white;

            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            &:before {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background-color: $helpme-main-color-darken;
              // display: inline-block;
              display: none;
            }

            &:active,
            &:focus,
            &:checked {
              border-color: $helpme-main-color-darken;
            }

            &:checked {
              outline: none;

              &:before {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
