.tab-bars {
  position: fixed;
  z-index: 12;
  bottom: 0;
  width: 100%;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 6px 0;
  font-size: 11px;

  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.13);

  transition: bottom 0.5s ease;
  bottom: 0;

  &.__hidden {
    bottom: -58px;
  }
}

.tab-bars a {
  display: flex;
  width: 25%;
  text-decoration: none;
  flex-direction: column;
  text-align: center;

  padding: 4px 0;
  color: #404040;
}

.tab-bars a {
  > i {
    margin-bottom: 6px;
    height: 18px;
    width: 100%;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  & {
    i.doc {
      background-image: url('/assets/img/mobile/tab-bar/docgray-icon.svg');
    }

    i.price {
      background-image: url('/assets/img/mobile/tab-bar/pricegray-icon.svg');
    }

    i.lawyer {
      background-image: url('/assets/img/mobile/tab-bar/lawyergray-icon.svg');
    }

    i.form-1 {
      background-image: url('/assets/img/mobile/tab-bar/form-1-gray-icon.svg');
    }
  }

  &:hover,
  &:active,
  &.active {
    color: #00b4e3;

    i.doc {
      background-image: url('/assets/img/mobile/tab-bar/docblue-icon.svg');
    }

    i.price {
      background-image: url('/assets/img/mobile/tab-bar/priceblue-icon.svg');
    }

    i.lawyer {
      background-image: url('/assets/img/mobile/tab-bar/lawyerblue-icon.svg');
    }

    i.form-1 {
      background-image: url('/assets/img/mobile/tab-bar/form-1-blue-icon.svg');
    }
  }
}

.tab-bars a.active {
  color: #00b4e3;
}

.tab-bars a > div {
  line-height: 1em;
}
