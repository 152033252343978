.changeRegistrationTemplateMobile {
  //모바일
  padding: 30px 0px 50px;
  max-width: 100%;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
  .changeRegistration {
    position: relative;
    margin-top: 21px;
    padding: 36px 0px;
    border-radius: 10px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:after {
      position: absolute;
      top: 12px;
      right: 0px;
      display: inline-block;
      content: '';
      width: 200px;
      height: 198px;
      background-image: url(../../../../assets/img/pages/main/hyo_circle_mobile.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
    .changeRegistrationContent {
      position: relative;
      margin-top: 36px;
      padding: 0 20px;
      padding-top: 36px;
      border-top: 1px solid #dcdbdc;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        padding-bottom: 18px;
        .subTitle {
          top: 0;
        }
      }
      p {
        margin: 0;
        color: #404040;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.64px;
        .bold {
          font-weight: 700;
          &.color {
            color: #006ac3;
          }
        }
      }
      p + p {
        margin-top: 8px;
      }
      .subTitle {
        margin: 0;
        color: #1e1e22;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.8px;
        .explain {
          position: relative;
          margin-left: 15px;
          color: #999;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.87;
          letter-spacing: -0.3px;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 1px;
            left: -9px;
          }
        }
      }
      .corpRegistration {
        .registrationBox {
          margin-top: 36px;
          &:first-child {
            margin-top: 24px;
          }
          .title {
            color: #1d5cb8;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.65;
          }
          .checkBox {
            margin-top: 16px;
          }
          .etc {
            .checkBoxInput {
              margin-top: 9px;
              margin-left: 32px;
              width: calc(100% - 32px);
            }
            .error {
              margin-left: 30px;
            }
          }
        }
      }
      .companyInfoBox {
        margin-top: 24px;
        .companyInfo {
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
          .title {
            color: #1d5cb8;
            font-size: 18px;
            font-weight: 400;
          }
          .inputText {
            margin-top: 10px;
            width: 100%;
          }
          .inputExplain {
            position: relative;
            margin-top: 8px;
            margin-left: 12px;
            color: #999;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.33;
            letter-spacing: -0.3px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 3px;
              left: -10px;
            }
          }
        }
      }
    }
  }
  .btnBox {
    margin-top: 40px;
    text-align: center;
    .corpBtn {
      cursor: pointer;
      position: relative;
      margin: 0 20px;
      padding: 0 24px;
      width: calc(100% - 40px);
      height: 60px;
      background-color: #2852af;
      border: none;
      border-radius: 30px;
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      text-align: left;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 24px;
        width: 19px;
        height: 30px;
        background-image: url(/assets/img/icons/corpArrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .btnExplain {
      position: relative;
      display: inline-block;
      margin-top: 10px;
      color: #88888a;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.87;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: -10px;
      }
    }
  }
  .corpsError {
    margin-top: 10px;
  }
  .error {
    display: inline-block;
    margin-top: 2px;
    color: #eb4242;
    font-size: 15px;
    font-weight: 400;
  }
}

.changeRegistrationTemplate {
  //pc
  max-width: 886px;
  .mainTitle {
    margin: 0;
    color: #000;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.47;
    text-align: center;
  }
  .changeRegistration {
    position: relative;
    margin-top: 21px;
    padding: 43px 46px;
    min-width: 886px;
    border: 1px solid #dcdbdc;
    border-radius: 10px;
    background-color: #fff;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: #1d5cb8;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:after {
      position: absolute;
      top: 8px;
      right: 41px;
      display: inline-block;
      content: '';
      width: 274px;
      height: 206px;
      background-image: url(../../../../assets/img/pages/main/hyo_circle.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
    .changeRegistrationContent {
      position: relative;
      margin-top: 47px;
      padding-top: 47px;
      padding-left: 140px;
      border-top: 1px solid #dcdbdc;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 16px;
        border-top: none;
        padding-bottom: 43px;
        .subTitle {
          top: 0;
        }
      }
      .subTitle {
        position: absolute;
        left: 0;
        top: 47px;
        margin: 0;
        color: #000;
        font-size: 21px;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: -0.84px;
        .explain {
          position: relative;
          margin-left: 10px;
          color: #999;
          font-size: 15px;
          line-height: 1.87;
          letter-spacing: -0.3px;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: -10px;
          }
        }
      }
      p {
        margin: 0;
        color: #404040;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.22;
        letter-spacing: -0.36px;
        .bold {
          font-weight: 700;
          &.color {
            color: #00b4e3;
          }
        }
      }
      p + p {
        margin-top: 10px;
        font-size: 16px;
      }
      .corpRegistration {
        display: flex;
        flex-wrap: wrap;
        .registrationBox {
          margin-top: 35px;
          width: 240px;
          &:nth-child(2n) {
            margin-left: 100px;
          }
          &:nth-child(-n + 2) {
            margin-top: 0;
          }
          .title {
            color: #1d5cb8;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.65;
          }
          .checkBox {
            margin-top: 16px;
          }
          .etc {
            .checkBoxInput {
              margin-top: 7px;
              margin-left: 30px;
              width: 210px;
            }
            .error {
              margin-left: 30px;
            }
          }
        }
      }
      .table {
        .tbody {
          tr {
            th {
              padding: 24px 0;
              font-size: 18px;
              font-weight: 400;
              text-align: left;
              vertical-align: top;
            }
            td {
              padding-top: 12px;
              padding-left: 20px;
              .inputText {
                width: 428px;
              }
              .inputExplain {
                position: relative;
                margin-left: 12px;
                color: #999;
                font-size: 15px;
                font-weight: 400;
                line-height: 1.6;
                letter-spacing: -0.3px;
                &:before {
                  content: '*';
                  display: inline-block;
                  position: absolute;
                  top: 3px;
                  left: -10px;
                }
              }
            }
            &:first-child {
              th {
                padding: 12px 0;
              }
              td {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
  .btnBox {
    margin-top: 46px;
    padding: 0 304px;
    .corpBtn {
      cursor: pointer;
      position: relative;
      padding: 0 30px;
      width: 277px;
      height: 48px;
      background-color: #2852af;
      border: none;
      border-radius: 24px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 27px;
        width: 15px;
        height: 20px;
        background-image: url(/assets/img/icons/corpArrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .btnExplain {
      position: relative;
      margin: 5px 53px 0 63px;
      color: #88888a;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.87;
      letter-spacing: -0.3px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: -10px;
      }
    }
  }
  .corpsError {
    margin-top: 10px;
  }
  .error {
    display: inline-block;
    margin-top: 2px;
    color: #eb4242;
    font-size: 15px;
    font-weight: 400;
  }
}
