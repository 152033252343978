@import '../../common/variable';
@import '../../common/mixin';

$top-nav-padding: 40px;
$side-padding: 16px;
$section-inter-margin: 80px;

$h1-font-size: 16px;
$h1-line-height: 26px;

$h2-bottom-margin: 24px;
$h2-line-height: 17px;
$h2-font-size: 17px;

$h2-content-between-margin: 80px;

$h3-font-size: 16px;
$h3-line-height: 18px;
$h3-bottom-margin: 24px;
$h3-padding: 16px;
$pricing-table-padding: 8px;

$content-headline-font-size: 16px;
$content-left-padding: 20px;

$content-font-size: 15px;
$content-line-height: 29px;
$content-font-color: #6b6b6b;

@mixin content($color: $content-font-color) {
  font-size: $content-font-size;
  line-height: $content-line-height;
  color: $color;

  strong {
    font-weight: 500;
    color: #404040;
  }
}

$content-sub-font-size: 14px;
$content-sub-line-height: 24px;
$content-sub-font-color: #909090;

@mixin subcontent($color: $content-sub-font-color) {
  font-size: $content-sub-font-size;
  line-height: $content-sub-line-height;
  color: $color;

  strong {
    font-weight: 500;
    color: $content-sub-font-color;
  }
}

$paragraph-bottom-margin: 16px;
$list-bottom-margin: 40px;
$list-left-padding: 20px;

@mixin nanum-square() {
  font-family: 'NanumSquare', 'Noto Sans KR', sans-serif;
}

@mixin heading($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 400;
  color: $text-color;

  strong {
    font-weight: 600;
  }
}

@mixin list() {
  $list-item-between-margin: 16px;
  $list-item-nested-between-margin: 5px;

  padding-left: $list-left-padding;
  margin-top: 0;
  margin-bottom: $list-bottom-margin;

  li {
    @include content();

    p {
      margin: 0;
    }

    ol,
    ul {
      @include subcontent();
      margin-top: $list-item-between-margin;

      li + li {
        margin-top: $list-item-nested-between-margin;
      }
    }
  }

  li + li {
    margin-top: $list-item-between-margin;
  }
}

@mixin no-list-style() {
  list-style-type: none;
  text-indent: inherit;

  li:before {
    display: none;
  }
}

@mixin unordered-list-style($padding) {
  list-style-type: none;
  text-indent: -14px;
  padding-left: $padding - 4px;

  li:before {
    // NOTE: content is 8px
    content: '•';
    letter-spacing: 0;
    display: inline-block;
    width: 8px;
    text-align: right;
    margin-right: 6px;
  }
}

@mixin yellow-block-content() {
  $block-padding: 16px;

  @include nanum-square();

  display: block;
  background-color: #fffdea;
  border: solid 1px #f5edae;
  border-radius: 4px;

  margin: 0;
  margin-bottom: 24px;
  padding: $block-padding;

  p {
    margin-top: 0;
    margin-bottom: 10px;

    font-size: 14px;
    line-height: 28px;
    font-weight: 400;

    &:first-child {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin table-style-list() {
  @include no-list-style();
  $table-list-border: 1px solid #e9e9e9;

  @include no-list-style();
  padding-left: 0;

  li {
    display: block;
    padding: 6px 20px;
    background-color: #f5f5f5;
    font-size: 14px;

    ul {
      @include no-list-style();
      padding-left: 0;
      margin: 6px -20px 0;

      li {
        background-color: white;
        font-size: 14px;
        color: #6b6b6b;

        position: relative;

        a {
          position: absolute;
          top: 10px;
          right: 10px;
          @include find-icon(20px);
        }
      }
    }
  }

  > li + li {
    margin-top: 10px;
  }

  > li {
    padding-bottom: 0;
    border-top: $table-list-border;
    border-bottom: $table-list-border;
  }
}

@mixin find-icon($icon-size: 24px) {
  text-decoration: none;
  display: flex;
  align-items: center;
  min-height: $icon-size;
  line-height: $icon-size;
  &:before {
    content: '';
    background-image: url('/assets/img/pages/pricing/find.svg');
    background-size: $icon-size;
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    position: relative;
    left: 0;
    margin-right: 4px;
  }
}

#view-mobile {
  .container {
    padding-top: 15px;
    // TODO: fix this magic number
    padding-bottom: 35px;
  }

  .page-submit {
    top: 0;
    a {
      font-size: 16px;
    }
  }

  .pricing-content {
    margin-left: 0;
    > div {
      padding-top: $top-nav-padding;
      padding-left: $side-padding;
      padding-right: $side-padding;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul,
      a {
        font-weight: 400;
      }

      > h1 {
        @include heading($h1-font-size, $h1-line-height);
        @include nanum-square();

        margin: 0 -1 * $side-padding;
        letter-spacing: -0.5px;

        // TODO:
        padding: 15px;
        text-align: center;

        background-color: #fafafa;
        border-bottom: 1px solid #e9e9e9;

        strong {
          font-weight: 600;
        }
      }

      > h2 {
        margin-top: $section-inter-margin;
        margin-bottom: $h2-bottom-margin;

        @include heading($h2-font-size, $h2-line-height);
        @include nanum-square();

        // h2 style
        background-color: white;
        display: inline-block;
        width: auto;
        position: relative;
        padding-right: 14px;

        // bar after content
        &:after {
          content: '';
          display: block;
          border-bottom: 1px solid #e0e0e0;

          width: calc(100vw - #{$side-padding * 2});
          position: absolute;
          z-index: -1;
          left: 0;
          right: $side-padding;
          top: $h2-line-height / 2;
        }
      }

      > h1 + h2 {
        margin-top: $h2-bottom-margin;
      }

      > p {
        @include content();

        margin-top: 0;
        margin-bottom: $paragraph-bottom-margin;
      }

      ol,
      ul {
        @include list();
      }

      ul {
        @include unordered-list-style($list-left-padding);
      }

      // p + h2
      > p {
        & + h2 {
          margin-top: $h2-content-between-margin - $paragraph-bottom-margin;
        }

        & + h3 {
          margin-top: $list-bottom-margin;
        }
      }

      // ol, ul + h2
      > ul,
      > ol {
        & + h2 {
          margin-top: $h2-content-between-margin - $list-bottom-margin;
        }
      }

      // table + h2
      table {
        & + h2 {
          margin-top: $h2-content-between-margin + $pricing-table-padding;
        }
      }

      // h3 + content
      > h3 {
        & + ol,
        & + ul {
          padding-left: $list-left-padding + $content-left-padding;
        }

        & + ul {
          @include unordered-list-style($list-left-padding + $content-left-padding);
        }

        & + p {
          padding-left: $content-left-padding;
        }
      }

      // h2 밑에 바로 컨텐츠가 올 경우
      > h2 {
        & + ol,
        & + ul {
          & > li {
            @include content();
          }
        }
      }

      > h3,
      pricing-calculator > h3 {
        margin-top: 0;
        margin-bottom: $h3-padding;

        @include heading($h3-font-size, $h3-line-height);

        // h3 style
        // HACK: See https://css-tricks.com/injecting-line-break/
        display: table;
        padding: 0 16px;
        line-height: 24px;

        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 6px;
          background-color: #e9e9e9;
          border-radius: 2px;
        }
      }

      #calculation {
        & + p {
          padding-left: 0;
        }
      }

      p > img {
        max-width: 100%;

        // this is a mobile stylesheet
        &[alt*='_desktop'] {
          display: none;
        }
      }

      a {
        font-size: $content-font-size;
        font-weight: 400;
        color: $helpme-main-color-darken;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      // benefit-img
      #benefit {
        $margin: 5px;

        & + p {
          padding: 0;
          margin: -1 * $margin 0;

          img {
            max-width: 100%;
            margin: $margin 0;

            // this is a mobile stylesheet
            &[alt*='_desktop'] {
              display: none;
            }
          }
        }

        & + p + ul {
          text-align: center;
          @include no-list-style();
          padding-left: 0;

          margin-top: 16px - $margin;

          li:before {
            content: '* ';
            color: $red-accent-color;
          }
        }
      }

      // pricing_description-blockquote
      #pricing_description {
        & + blockquote,
        & + * + blockquote {
          @include yellow-block-content();
        }
      }

      #pricing_list {
        & + ul {
          @include no-list-style();
          margin: 0;
          padding: 0;

          li {
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid #ececec;
            padding: 20px;

            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            em {
              font-style: normal;

              code {
                color: black;
                font-size: 20px;
                font-family: 'Noto Sans KR', sans-serif;
              }
            }
          }

          li + li {
            margin-top: 10px;
          }
        }

        & + ul + hr {
          border: none;

          & + ul {
            @include list();

            li + li {
              margin-top: 5px;
            }
          }
        }
      }

      // pricing-table
      #pricing {
        // just for line break
        & + hr,
        & + p + hr {
          margin: -1 * $pricing-table-padding;
          border: none !important;
        }

        &,
        & + hr,
        & + p + hr {
          // top table
          & + table {
            border-collapse: collapse;
            &:before {
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
          }

          // bottom table
          & + table + table {
            border-collapse: collapse;
            position: relative;
            margin-top: $pricing-table-padding * 2 !important;

            &:before {
              border-top-left-radius: 0 !important;
              border-top-right-radius: 0 !important;
            }

            // hiding center shadow
            &:after {
              content: '';
              display: block;
              background-color: white;
              height: 15px;
              width: calc(100% + #{$pricing-table-padding * 2 - 2px});
              position: absolute;
              top: -15px;
              left: -1 * $pricing-table-padding + 1px;
            }

            thead {
              display: none;
            }

            tbody {
              tr:first-child {
                border-top: 1px solid $text-color;
              }
            }
          }

          &,
          & + table,
          & + table + table {
            @include nanum-square();
          }

          & + table,
          & + table + table {
            margin-top: $pricing-table-padding + $h3-padding;
            vertical-align: top;

            width: calc(100% - #{$pricing-table-padding * 2});
            position: relative;
            left: $pricing-table-padding;

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: $pricing-table-padding * -1;
              left: $pricing-table-padding * -1;
              right: $pricing-table-padding * -1;
              bottom: $pricing-table-padding * -1;

              border-radius: 4px;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
              background-color: transparent;
              border: solid 1px #ececec;
            }

            thead,
            tbody {
              tr {
                th,
                td {
                  font-weight: 400;

                  strong,
                  em {
                    font-weight: 600;
                  }

                  em {
                    font-style: normal !important;
                    color: #eb4d59;
                  }

                  // text
                  &:nth-child(2n + 1) {
                    text-align: left;
                    padding-left: 10px;
                  }
                  // number
                  &:nth-child(2n) {
                    text-align: right;
                    padding-right: 10px;
                  }
                }
              }
            }

            thead {
              tr {
                border-bottom: 1px solid $text-color;

                th {
                  padding: 25px - $pricing-table-padding 0 25px;
                  font-size: 16px;

                  &:first-child {
                    padding-left: 0;
                    text-align: center;
                  }

                  &:last-child {
                    padding-right: 40px - $pricing-table-padding;
                    text-align: right;

                    strong {
                      font-size: 22px;
                      color: black;
                    }
                  }
                }
              }
            }

            tbody {
              tr {
                & {
                  th,
                  td {
                    padding-top: (53px - $content-line-height) / 2;
                    padding-bottom: (53px - $content-line-height) / 2;
                    font-size: 14px;
                    color: $content-font-color;
                  }
                }

                &:first-child {
                  border-bottom: 1px solid #e0e0e0;

                  th,
                  td {
                    padding-top: (61px - $content-line-height) / 2;
                    padding-bottom: (61px - $content-line-height) / 2;

                    color: $text-color;
                    font-weight: 500;
                  }
                }

                &:nth-child(2) {
                  border-top: none;
                }
              }
            }
          }

          & + table + table {
            & + h2 {
              margin-top: $pricing-table-padding + $section-inter-margin;
            }
          }

          & + table + table + ul {
            margin-top: 20px + $pricing-table-padding;
            color: #909090;
            font-size: 16px;

            li + li {
              margin-top: 5px;
            }
          }
        }
      }

      // tax-reduction-table
      #taxreduction {
        & + p {
          margin-bottom: $h2-bottom-margin;
        }

        & + ul,
        & + p + ul {
          @include table-style-list();
        }
      }

      // strength-ul {
      #strength {
        $img-width: 50px;
        $img-margin: 10px;

        & + ul {
          padding-left: $img-width + $img-margin;
          margin-bottom: 0;

          > li {
            position: relative;
            font-size: 16px;
            color: #404040;
            font-weight: 500;

            @media screen and (max-width: 320px) {
              letter-spacing: -0.7px;
            }

            img {
              position: absolute;
              left: -1 * ($img-width + $img-margin);
              width: $img-width;

              top: 3px;
            }
          }

          &,
          & ul {
            @include no-list-style();
          }

          p {
            margin: 0;
          }

          & {
            font-size: 18px;
          }

          ul {
            @include list();
            padding-left: 0;
            margin-top: 5px;
            margin-bottom: 0;

            > li {
              color: #909090;
            }
          }

          li + li {
            margin-top: $h2-bottom-margin;
          }
        }
      }

      // deduction-p-ul
      #deduction {
        $padding: 0;

        & + p + ul {
          @include no-list-style();

          margin-top: 0;
          padding-left: $padding;
        }
      }

      // densearea_description-pre
      #densearea_description {
        $densearea-description-width: 100%;

        & + hr {
          border: none;
        }

        & + blockquote,
        & + * + blockquote {
          display: inline-block;
          width: $densearea-description-width;
          background-color: #f5f5f5;
          border: solid 1px #e9e9e9;
          border-radius: 4px;

          margin: 0;
          padding: 20px;

          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;

            strong {
              font-size: 16px;
              font-weight: 500;
            }

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          & + p {
            display: inline-block;
            margin-bottom: 0;
            margin-top: 15px;
            margin-left: 0;
            vertical-align: top;

            > img {
              max-width: 100%;
            }
          }
        }
      }

      // process-desc-ul
      #process_desc {
        & + ul,
        & + p + ul {
          @include no-list-style();

          margin: 0;
          padding-left: 0;

          li + li {
            margin-top: 10px;
          }
        }
      }

      // process-ul
      $process-offset: 8px;
      #process {
        & + ul {
          @include no-list-style();

          $margin-between-indicator: 20px;
          $list-left-padding: 15px;
          $bullet-size: 10px;

          margin-top: 20px;
          padding-left: $list-left-padding + $margin-between-indicator + $process-offset;

          // &h3 {
          //   margin-top: 0;
          //   padding-left: $margin-between-indicator + $process-offset;
          // }

          li {
            position: relative;

            &:after {
              content: '';
              width: $bullet-size;
              height: $bullet-size;
              border-radius: $bullet-size;
              background-color: white;
              border: 3px solid #ccc;

              display: inline-block;
              position: absolute;
              left: -1 * ($list-left-padding + $margin-between-indicator) + $process-offset;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              z-index: 2;
            }
          }

          li + li {
            $width: 2px;

            &:before {
              content: '';
              display: inline-block;
              width: 2px;
              height: 75px;
              background-color: #ccc;

              position: absolute;
              left: -1 * ($list-left-padding + $margin-between-indicator) + $bullet-size / 2 + $width + $process-offset;
              top: -35px;
              z-index: 1;
            }
          }

          li:last-child:before {
            top: -60px;
          }

          & + p {
            margin-left: $content-left-padding;
          }
        }
      }

      h2#process {
        & + ul {
          margin-top: 0;
          padding-left: $content-left-padding + $process-offset + 1px;
        }
      }

      // required_documents-h3-ul
      $checkbox-size: 22px;
      $checkbox-padding: 10px;
      #required_documents,
      #required_document_e,
      #required_document_d,
      #required_document_f {
        & + ol,
        & + h3 + ol {
          list-style-type: none;
          padding-left: $content-left-padding + $checkbox-size + $checkbox-padding / 2;
          margin-bottom: 48px;

          li {
            position: relative;

            &:before {
              content: '';
              background-image: url('/assets/img/pages/pricing/check.svg');
              width: $checkbox-size;
              height: $checkbox-size;
              background-size: $checkbox-size - 1px;
              background-position: center;
              background-repeat: no-repeat;
              display: inline-block;
              position: absolute;
              left: -1 * ($checkbox-size + $checkbox-padding);
              top: $checkbox-size / 4 - 2px;
            }
          }

          li + li {
            margin-top: 10px;
          }

          & + p {
            margin-top: -1 * $h3-bottom-margin;
            padding-left: $content-left-padding;
          }
        }
      }

      h2#required_documents {
        & + ol {
          padding-left: $checkbox-size + $checkbox-padding;

          & + p {
            padding-left: 0;
          }
        }
      }

      // steps-ul
      #steps {
        & + ul {
          list-style-type: none;
          padding-left: 0;

          li {
            em {
              font-style: normal;
              font-weight: 700;
              margin-right: 10px;
            }
          }

          li + li {
            margin-top: 10px;
          }
        }
      }

      // article_what-blockquote
      #article_what {
        & + ol {
          margin-bottom: 24px;

          li + li {
            margin-top: 24px;
          }

          li {
            blockquote {
              margin: 0;
              margin-top: 8px;
              @include subcontent();

              p {
                margin: 0;
              }
            }
          }

          & + p {
            position: relative;
            > a {
              @include find-icon();
            }
          }
        }
      }

      #find_link {
        & + ol + p,
        & + ul + p {
          position: relative;
          > a {
            @include find-icon();
          }
        }
      }
    }

    call-to-action-section {
      margin-top: $h2-content-between-margin;
      margin-bottom: -1 * $paragraph-bottom-margin;
      padding-top: 0;

      &,
      & .cta {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: white;
      }

      .cta {
        border-top: 1px solid #e0e0e0;

        h1 {
          font-size: 20px;
          line-height: 1.2em;
          word-break: keep-all;
          white-space: pre-wrap;
          margin-bottom: 24px;

          @media screen and (min-width: 340px) and (max-width: 370px) {
            letter-spacing: -0.7px;
          }

          position: relative;
          text-align: center;
        }

        h3 {
          font-size: 16px;
        }

        a {
          display: block;

          > button {
            @include round-arrow-cta(18px);
            font-size: 18px;
            background-color: $helpme-main-color;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .pricing-content.is-calculator-active {
    > * > #pricing {
      &,
      & + hr,
      & + p,
      & + p + hr {
        display: none !important;
        & + table,
        & + table + table {
          display: none !important;
        }
      }
    }
  }
}
