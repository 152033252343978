.certificateBoxMobile {
  //모바일
  margin-top: 28px;
  .certificateImg {
    width: 100%;
  }
}

.certificateBox {
  //pc
  padding: 24px 30px;
  width: 514px;
  border: 1px solid #b8b9bb;
  border-radius: 8px;
  background-color: #fff;
}
